<template>
  <!-- 返回上一页 -->
  <div>
    <div class="back">
      <i class="el-icon-arrow-left" @click="backbtn"></i>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    backbtn() {
      this.$router.go(-1); //返回上一层
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  padding: 15px 30px;
  font-size: 56px;
  color: #242424;
}
</style>
